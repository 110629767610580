import React from 'react';
import { BlockUI } from 'primereact/blockui';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.css';

const Loading = (props) => {
	const { children, loading } = props;

	return (
		loading
			? (
				<BlockUI blocked={loading}>
					<div className="content">
						{children}
					</div>
					<ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="2" animationDuration=".5s" />
				</BlockUI>
			)
			: (
				children
			)
	);
};

export default Loading;
