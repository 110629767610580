import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { httpUnintercepted } from '../../services/httpService';

const getAllUserApis = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/apis/all/get`);
	return result.data;
};
export const useGetAllUserApis = (userId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getAllUserApis'],
	queryFn: () =>
		getAllUserApis(userId)
			.then((res) => {
				return res.apis;
			}),
});

const deleteApi = async (userId, datapoolId, apiId) => {
	const result = await httpUnintercepted.delete(`user/${userId}/datapools/${datapoolId}/apis/${apiId}/delete`);
	return result.data;
};

export const useDeleteApi = (userId, queryClient, setDeleteApiLoading) => useMutation({
	mutationFn: (rowData) => deleteApi(userId, rowData.datapool_id, rowData.query_id),
	onSuccess: () => {
		queryClient.invalidateQueries('getAllUserApis');
	},
	onSettled: () => {
		setDeleteApiLoading(false);
	},
});

const getPublicDatapools = async () => {
	const result = await httpUnintercepted.get(`/datapools/public/all/get`);
	return result.data;
};

export const useGetPublicDatapools = () => useQuery({
	staleTime: Infinity,
	queryKey: ['getPublicDatapools'],
	queryFn: () => getPublicDatapools(),
});

const getDatapools = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/all/get`);
	return result.data;
};

export const useGetUserDatapools = (userId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getUserDatapools'],
	queryFn: () => getDatapools(userId),
});

const getApiSecret = async (userId, datapoolId, apiId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/apis/${apiId}/get`);
	return result.data;
};

export const useGetApiSecret = (userId, data) => useQuery({
	staleTime: Infinity,
	enabled: !!data.query_id,
	queryKey: ['getAPISecret', data.auth_zero_id],
	queryFn: () =>
		getApiSecret(userId, data.datapool_id, data.auth_zero_id)
			.then((res) => {
				return res.client_secret;
			}),
});

const getDataXDatapoolById = async (id, publicStatus, userId) => {
	if (publicStatus) {
		const result = await httpUnintercepted.get(`/datapools/public/${id}/get`);
		return result.data;
	}
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${id}/get`);
	return result.data;
};

export const useGetDataXDatapoolById = (selectedDatapool, userId) => useQuery({
	staleTime: Infinity,
	enabled: !!selectedDatapool.alias,
	queryKey: ['getDatapoolById', selectedDatapool.alias],
	queryFn: () =>
		getDataXDatapoolById(selectedDatapool.mongo_id, selectedDatapool.status === 'public', userId),
});
