import React, { useEffect, useState } from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetDataXDatapoolById } from '../../../Home/queryHooks';
import { useGetPublicDatapools, useGetUserDatapools } from '../../queryHooks';
import { Loading } from '../../../../components';

const CreateApiDialog = () => {
	const { user } = useAuth0();

	const navigate = useNavigate();

	const [selectedDatapool, setSelectedDatapool] = useState({});
	const [datapool, setDatapool] = useState(null);
	const [datapoolType, setDatapoolType] = useState('Public');
	const [loading, setLoading] = useState(false);

	const navigateToDatapool = useGetDataXDatapoolById(selectedDatapool, user?.sub);

	const publicDatapools = useGetPublicDatapools();

	const userDatapools = useGetUserDatapools(user?.sub);

	const options = () => {
		if (publicDatapools.isSuccess && userDatapools.isSuccess) {
			if (datapoolType === 'Public') {
				return publicDatapools.data?.datapools?.map((item) => {
					return { ...item, datapool_name: item.name };
				});
			}
			return userDatapools.data?.datapools?.filter((item) => (item.status === 'private') && item.records);
		}
		return [];
	};

	useEffect(() => {
		if (navigateToDatapool.isSuccess) {
			navigate('/AdvancedSearch', { state: { selectedDatapool: { ...navigateToDatapool.data, ...selectedDatapool } } });
		}
	}, [selectedDatapool, navigateToDatapool.data]);

	return (
		<div className="create-api-choose-datapool">
			<div className="instructions">
				<p className="title">
					How to Create an API:
				</p>
				<p>1. Begin by selecting a datapool and clicking on the &quot;OPEN&quot; button to navigate to the datapool landing page.</p>
				<p>2. On the landing page of the datapool, utilize the Search interface to craft your desired query.</p>
				<p>3. Execute the query by clicking on the &quot;SEARCH&quot; button.</p>
				<p>4. Review the resulting data. If satisfied, proceed with creating the API by clicking on the &quot;CREATE API&quot;
					button that appears on the webpage.
				</p>
			</div>
			<div className="radio-buttons">
				<div className="flex align-items-center">
					<RadioButton
						inputId="type1"
						name="public"
						value="Public"
						onChange={(e) => {
							setDatapool(null);
							setDatapoolType(e.value);
						}}
						checked={datapoolType === 'Public'}
					/>
					<label htmlFor="type1" className="ml-2">Public</label>
				</div>
				<div className="flex align-items-center">
					<RadioButton
						inputId="type2"
						name="private"
						value="Private"
						onChange={(e) => {
							setDatapool(null);
							setDatapoolType(e.value);
						}}
						checked={datapoolType === 'Private'}
					/>
					<label htmlFor="type2" className="ml-2">Private</label>
				</div>
			</div>
			<Loading loading={publicDatapools.isLoading || userDatapools.isLoading}>
				<div className="dropdown-and-button">
					<Dropdown
						value={datapool}
						onChange={(e) => setDatapool(e.value)}
						options={options()}
						optionLabel="datapool_name"
						placeholder="Select a Datapool"
						className="w-full md:w-14rem"
					/>
					<Button
						label="Open"
						onClick={() => {
							setLoading(true);
							setSelectedDatapool(datapool);
						}}
						disabled={loading || !datapool}
						loading={loading}
					/>
				</div>
			</Loading>
		</div>
	);
};

export default CreateApiDialog;
