import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import './styles.css';
import { useAddUsersToDatapool, useGetDatapoolUsersInverse } from '../../../../queryHooks';

const AddUserDialog = ({ dialogVisibility, setDialogVisibility, location }) => {
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [addUserLoading, setAddUserLoading] = useState(false);
	const queryClient = useQueryClient();

	const users = useGetDatapoolUsersInverse(location.state.userId, location.state.datapoolId);

	const addUsers = useAddUsersToDatapool(location.state.userId, location.state.datapoolId, setSelectedUsers, queryClient, setAddUserLoading);

	const userTemplate = (data) => {
		return `${data.name} ${data.surname}`;
	};

	const handleAddUsers = () => {
		setAddUserLoading(true);
		addUsers.mutate(selectedUsers);
	};

	const handleClose = () => {
		setDialogVisibility(false);
		setSelectedUsers([]);
	};

	return (
		<Dialog className="add-user" header="Add Users" visible={dialogVisibility} style={{ width: '50vw' }} onHide={handleClose} contentStyle={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px 20px' }}>
			<div className="buttons-container">
				{selectedUsers.length > 0
					? <p>You have selected {selectedUsers.length} users.</p>
					: <p>Please select users to add to datapool.</p>
				}
				
				<Button label="Add Users to Datapool" onClick={handleAddUsers} disabled={selectedUsers.length === 0} loading={addUserLoading} />
			</div>
			<div className="users-table-table-container">
				<DataTable
					value={users.data}
					showGridlines
					stripedRows
					selectionMode="multiple"
					selection={selectedUsers}
					onSelectionChange={(e) => setSelectedUsers(e.value)}
					metaKeySelection={false}
					paginator
					rows={20}
					loading={users.isLoading}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
					<Column body={userTemplate} header="User" />
					<Column field="email" header="User e-mail" />
					{/* <Column body={checkBox} /> */}
					{/* <Column body={userTemplate} header="User" /> */}
					{/* <Column body={roleTemplate} header="Access Level" /> */}
					{/* <Column body={actionsTemplate} header="Actions" /> */}
					{/* <Column body={projectsTemplate} header="Project" /> */}
				</DataTable>
			</div>
		</Dialog>
	);
};

export default AddUserDialog;
