import React, { useState } from 'react';
import './styles.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from 'primereact/dialog';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import {
	useDeleteApi,
	useGetAllUserApis, useGetApiSecret,
} from './queryHooks';
import { ApiCallSnippet, CreateApiDialog } from './components';

const MyApis = () => {
	const { user } = useAuth0();
	const [loadingSecret, setLoadingSecret] = useState({});
	const [toggleCreateApiDialog, setToggleCreateApiDialog] = useState(false);
	const [deleteApiLoading, setDeleteApiLoading] = useState(false);

	const [selectedApi, setSelectedApi] = useState(null);
	const [clickedSecret, setClickedSecret] = useState({});

	const queryClient = useQueryClient();

	const myApis = useGetAllUserApis(user?.sub);

	const deleteApi = useDeleteApi(user?.sub, queryClient, setDeleteApiLoading);

	const apiSecret = useGetApiSecret(user?.sub, clickedSecret);

	const updateApis = () => {
		if (myApis.isSuccess) {
			const newApis = [...myApis.data];
			const ind = myApis.data.findIndex((a) => a.query_id === clickedSecret.query_id);
			if (ind !== -1) {
				newApis[ind].clientSecret = apiSecret.data;
			}
			return newApis;
		}
		return [];
	};

	const updateLoading = (key, bool) => {
		const newLoading = { ...loadingSecret };
		newLoading[key] = bool;
		setLoadingSecret(newLoading);
	};
	const handleGetSecret = (data) => {
		updateLoading(data.api_id, true);
		setClickedSecret(data);
	};

	const handleCopyText = (data) => {
		navigator.clipboard.writeText(data);
	};

	const datapoolTemplate = (rowData) => {
		return (
			<div>
				<p>{rowData.mongo_id}</p>
				<h5>{rowData.datapool_name}</h5>
			</div>
		);
	};

	const handleShowDetails = (data) => {
		setSelectedApi(data);
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="actions">
				<Button
					rounded
					text
					icon="fa-duotone fa-eye"
					onClick={() => handleShowDetails(rowData)}
				/>
				<Button
					rounded
					text
					icon="fa-duotone fa-trash-can"
					onClick={() => {
						setDeleteApiLoading(true);
						deleteApi.mutate(rowData);
					}}
					loading={deleteApiLoading}
				/>
			</div>
		);
	};

	const clientIdTemplate = (data) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
				<p>{data.auth_zero_id}</p>
				<Button text rounded icon="fa-solid fa-copy" onClick={() => handleCopyText(data.auth_zero_id)} />
			</div>

		);
	};

	const clientSecretTemplate = (data) => {
		if (data.clientSecret) {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
					<p>{data.clientSecret}</p>
					<Button text rounded icon="fa-solid fa-copy" onClick={() => handleCopyText(data.clientSecret)} />
				</div>

			);
		}
		const rowStatus = loadingSecret[data.api_id];

		return (
			<button
				aria-label="Get client secret"
				type="button"
				style={{ background: 'unset', border: 'unset', cursor: 'pointer', height: '2rem' }}
				onClick={() => handleGetSecret(data)}
			>
				<Skeleton width="330px" height="2rem" className="mb-2" animation={rowStatus ? 'wave' : 'none'}>
					<p>
						Click to View Client Secret
					</p>
				</Skeleton>
			</button>
		);
	};

	const headerTemplate = () => {
		return (
			<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					label="Create Api"
					onClick={() => {
						setToggleCreateApiDialog(true);
					}}
				/>
			</div>
		);
	};

	return (
		<div className="my-apis">
			<div className="my-apis-table">
				<h3>My APIs</h3>
				<DataTable
					value={updateApis() || []}
					showGridlines
					stripedRows
					paginator
					rows={5}
					rowsPerPageOptions={[5, 10, 25, 50]}
					header={headerTemplate}
					loading={myApis.isLoading}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column field="query_name" header="Api Name" />
					<Column header="Datapool" body={datapoolTemplate} />
					<Column field="auth_zero_id" header="Client Id" body={clientIdTemplate} style={{ width: '350px' }} />
					<Column field="clientSecret" header="Client Secret" body={clientSecretTemplate} style={{ width: '350px' }} />
					<Column header="Action" body={actionsTemplate} />
				</DataTable>
			</div>
			{
				selectedApi ? (
					<ApiCallSnippet selectedApi={selectedApi} />
				) : null
			}
			<Dialog header="Create Api" visible={toggleCreateApiDialog} style={{ width: '70vw' }} onHide={() => setToggleCreateApiDialog(false)}>
				<CreateApiDialog />
			</Dialog>
		</div>
	);
};

export default MyApis;
