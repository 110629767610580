import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useQueryClient } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
	useDeleteDatafile,
	useGetDatafiles, useGetPresignedUrl,
	useGetRelatedDatapools,
} from '../../queryHooks';
import './styles.css';

const DataFilesTable = () => {
	const { user } = useAuth0();

	const queryClient = useQueryClient();

	const [toggleRelatedDatapoolsDialog, setToggleRelatedDatapoolsDialog] = useState(false);
	const [selectedDatafile, setSelectedDatafile] = useState('');
	const [downloadDatafile, setDownloadDatafile] = useState(null);
	const [toggleDownload, setToggleDownload] = useState(0);
	const [deleteDatafileLoading, setDeleteDatafileLoading] = useState(false);

	const datafiles = useGetDatafiles(user);

	const relatedDatapools = useGetRelatedDatapools(user, selectedDatafile);

	const deleteDatafile = useDeleteDatafile(user, queryClient, setDeleteDatafileLoading);

	const presignedUrl = useGetPresignedUrl(user, downloadDatafile);

	useEffect(
		() => {
			if (presignedUrl.isSuccess) {
				window.open(presignedUrl.data, '_blank');
			}
		}, [toggleDownload, presignedUrl.data]
	);

	const handleDelete = (data) => {
		setDeleteDatafileLoading(true);
		deleteDatafile.mutate(data);
	};

	const actionsTemplate = (rowData) => {
		return (
			<div style={{ display: 'flex', gap: '8px' }}>
				<Button
					rounded
					outlined
					icon="fa-duotone fa-download"
					onClick={() => {
						setDownloadDatafile(rowData);
						setToggleDownload((prev) => prev + 1);
					}}
					loading={presignedUrl.isLoading}
				/>
				<Button
					rounded
					outlined
					icon="fa-solid fa-file-xmark"
					style={{ color: 'red', backgroundColor: 'white' }}
					onClick={() => handleDelete(rowData)}
					disabled={rowData.has_datapool}
					loading={deleteDatafileLoading}
				/>
			</div>
		);
	};

	const datapoolsTemplate = (rowData) => {
		return (
			<Button
				className="chip"
				style={rowData.has_datapool ? { backgroundColor: '#d6fad4' } : { backgroundColor: '#ffb1b1' }}
				onClick={() => {
					if (rowData.has_datapool) {
						setSelectedDatafile(rowData.datafile_id);
						setToggleRelatedDatapoolsDialog(true);
					}
				}}
			>
				<p
					style={{ color: rowData.has_datapool ? '#3b7935' : '#ab3030', fontWeight: 'bold' }}
				>
					{rowData.has_datapool ? 'View Related Datapools' : 'No Related Datapools'}
				</p>
			</Button>
		);
	};

	if (datafiles.error) {
		return <p>{datafiles.error}</p>;
	}

	return (
		<div className="files">
			{/* <div className="buttons-container"> */}
			{/*	<Button label="Upload File" onClick={() => { setRenderUploader(true); }} /> */}
			{/* </div> */}
			<div className="data-files-table-container">
				<h1 className="title">My Files</h1>
				<DataTable
					value={datafiles.data}
					showGridlines
					stripedRows
					paginator
					rows={5} 
					rowsPerPageOptions={[5, 10, 25, 50]}
					loading={datafiles.isLoading}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column field="filename" header="File Name" />
					<Column field="has_datapool" header="Datapools" body={datapoolsTemplate} />
					<Column field="creation_time" header="Uploaded" />
					<Column field="name" header="Actions" body={actionsTemplate} />
					{/* <Column body={tagTemplate} header="Tag" /> */}
					{/* <Column body={projectsTemplate} header="Project" /> */}
				</DataTable>
			</div>
			<Dialog header="Related Datapools" visible={toggleRelatedDatapoolsDialog} style={{ width: '50vw' }} onHide={() => setToggleRelatedDatapoolsDialog(false)}>
				<DataTable value={relatedDatapools.data} showGridlines stripedRows loading={relatedDatapools.isLoading}>
					<Column field="mongo_id" header="Datapool Id" />
				</DataTable>
			</Dialog>
		</div>
	);
};

export default DataFilesTable;
