import React, { useMemo } from 'react';
import { AdvancedSearchComponent, constructElasticQuery } from '@scioservices/advanced-search-library/dist';
import { useAuth0 } from '@auth0/auth0-react';
import { Fieldset } from 'primereact/fieldset';
import { ApiCodeGenerator, Loading } from '../../../../components';
import { sortArrayOfObjectsByPropertyValue } from '../../../../utils/functions';
import { useGetApiSecret, useGetDataXDatapoolById } from '../../queryHooks';

const ApiCallSnippet = (props) => {
	const { selectedApi } = props;
	const { user } = useAuth0();

	const dataXDatapool = useGetDataXDatapoolById(selectedApi, user?.sub);

	const apiSecretIntercepted = useGetApiSecret(user?.sub, selectedApi);
	
	const mongoQuery = JSON.parse(selectedApi.query);

	const elasticQuery = useMemo(() => {
		if (mongoQuery) {
			const elastic = constructElasticQuery(JSON.stringify(mongoQuery));
			return elastic[0];
		}
		return null;
	}, [mongoQuery]);

	const filters = () => {
		let newFilters = dataXDatapool?.data?.filters;
		if (!(newFilters instanceof Array)) return [];
		newFilters = newFilters.map((item) => {
			const temp = { ...item, valueEditorType: item.type };
			if (item.values) {
				let sortedValues = sortArrayOfObjectsByPropertyValue(item.values, 'label');
				sortedValues = sortedValues.map((val) => {
					let newLabel = val.label;
					let newName = val.name;
					if (val.label === 'na') {
						newLabel = 'NA';
					}
					if (val.name === 'na') {
						newName = 'NA';
					}
					return { label: newLabel, name: newName };
				});
				temp.values = sortedValues;
			}
			delete temp.validator;
			return temp;
		});
		newFilters = sortArrayOfObjectsByPropertyValue(newFilters, 'label');
		console.log(newFilters);
		return newFilters;
	};

	return (
		<div className="code-snippet-container">
			<Loading loading={dataXDatapool.isLoading || apiSecretIntercepted.isLoading}>
				<Fieldset legend="QUERY">
					<AdvancedSearchComponent
						addIcon={<i className="fa-solid fa-plus" />}
						deleteIcon={<i className="fa-solid fa-x" />}
						filters={filters()}
						query={mongoQuery}
						setQuery={() => {}}
						setValid={() => {}}
					/>
				</Fieldset>
				<ApiCodeGenerator
					datapool={selectedApi.mongo_id}
					configuration={[
						{
							requestBody: {
								mode: 'raw',
								raw: JSON.stringify({
									client_id: selectedApi.auth_zero_id,
									client_secret: apiSecretIntercepted.data,
									audience: 'https://datapool.scio.services',
									grant_type: 'client_credentials',
								}),
							},
							requestConfiguration: {
								url: 'https://sciosystems.eu.auth0.com/oauth/token',
								method: 'POST',
								header: 'content-type: application/json',
							},
						},
						{
							requestBody: {
								mode: 'raw',
								raw: JSON.stringify(elasticQuery),
							},
							requestConfiguration: {
								url: `${process.env.REACT_APP_BACKEND_URL}/api/exposed/${selectedApi.alias}/documents/{from}/{to}`,
								method: 'POST',
								header: ['authorization: Bearer "Your token"', 'content-type: application/json'],
							},
							additionalTextOnRequest: ['// replace "from" with a number, to specify the starting index for elastic search', '// replace "to" with a number, to specify the ending index for elastic search'],
						},
					]}
				/>
			</Loading>
		</div>
	);
};

export default ApiCallSnippet;
