import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import './styles.css';
import UploadComponent from '@scioservices/upload-component';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ToolsService from '../../services/httpService/toolsService';

const DataCleaner = () => {
	const fileUploadRef = useRef(null);

	// const [loading, setLoading] = useState(false);

	const toast = useRef(null);

	const [generatedFiles, SetGeneratedFiles] = useState(false);

	const [dataLink, SetDataLink] = useState(null);
	const [spssLink, SetSpssLink] = useState(null);

	const [totalSimpleSize, setTotalSimpleSize] = useState(0);
	const [totalDataSize, setTotalDataSize] = useState(0);
	const [totalCodebookSize, setTotalCodebookSize] = useState(0);
	const [totalXlsFormSize, setTotalXlsFormSize] = useState(0);

	const [simpleProgress, setSimpleProgress] = useState(0);
	const [dataProgress, setDataProgress] = useState(0);
	const [codebookProgress, setCodebookProgress] = useState(0);
	const [formProgress, setFormProgress] = useState(0);

	// region NEW CODE

	const { user } = useAuth0();
	const location = useLocation();
	const [completedQuestionnaireUploads, setCompletedQuestionnaireUploads] = useState([]);
	const [completedCodebookUploads, setCompletedCodebookUploads] = useState([]);
	const [completedSurveyUploads, setCompletedSurveyUploads] = useState([]);

	const [questionnaire, setQuestionnaire] = useState();
	const [codebook, setCodebook] = useState();
	const [survey, setSurvey] = useState();

	const [checked, setChecked] = useState(false);
	const [stataCompatible, setStataCompatible] = useState(false);
	const [downloadLink, setDownloadLink] = useState();

	const [form, setForm] = useState(false);
	const [data, setData] = useState(false);

	const [datafile, setDatafile] = useState();

	const [loading, setLoading] = useState(false);

	// useEffect(() => {
	// 	console.log(location.state.token);
	// 	console.log(completedUploads);
	// 	if (completedUploads.length > 0) {
	// 		setDatafile(completedUploads[0]);
	// 	}
	// }, [completedUploads]);

	// const handleTransform = () => {
	// 	ToolsService.transformCrop(user.sub, selectedModel.code, datafile)
	// 		.then((r) => {
	// 			setDownloadLink(r);
	// 		});
	// };
	//
	// const handleDownload = () => {
	// 	axios({
	// 		url: downloadLink, // your url
	// 		method: 'GET',
	// 		responseType: 'blob', // important
	// 	}).then((response) => {
	// 		const url = window.URL.createObjectURL(new Blob([response.data]));
	// 		const link = document.createElement('a');
	// 		link.href = url;
	// 		link.setAttribute('download', datafile); // or any other extension
	// 		document.body.appendChild(link);
	// 		link.click();
	// 		link.remove();
	// 	});
	// };

	// endregion

	const onBasicUpload = (event) => {
		setLoading(true);
		setDataProgress(50);

		const get_file_content = (file) => {
			return new Promise((acc, err) => {
				const reader = new FileReader();
				reader.onload = (ev) => { acc(ev.target.result); };
				reader.onerror = (er) => { er(er); };
				reader.readAsArrayBuffer(file);
			});
		};

		const contents = event.files.map(
			(item) => {
				const itemContent = get_file_content(item).then(
					(response1) => {
						const responseData = response1;

						const config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://api.node.scio.services/api/naupload',
							headers: {
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							},
							responseData,
						};

						axios.request(config)
							.then((response2) => {
								const type = 'simple';
								const res = response2.data;

								const urls = {
									type,
									form,
									data: res,
									codebook,
								};

								const config2 = {
									method: 'post',
									maxBodyLength: Infinity,
									url: 'https://api.node.scio.services/api/allstata',
									// url: 'http://localhost:8192/api/allstata',
									headers: {
										'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
									},
									data: urls,
								};

								axios.request(config2)
									.then((response3) => {
										const result = response3.data;

										const element = document.createElement('a');
										element.setAttribute('href', result.download_link);
										element.setAttribute('download', '');
										element.style.display = 'none';
										document.body.appendChild(element);
										element.click();
										document.body.removeChild(element);
										//
										//
										// window.open(result.download_link_spss, '_blank');
										//
										// /*let nelement = document.createElement('a');
										// nelement.setAttribute('href', result.download_link_spss);
										// // element.setAttribute('download');
										// nelement.style.display = 'none';
										// document.body.appendChild(nelement);
										// nelement.click();
										// document.body.removeChild(nelement);*/

										/* SetDataLink(result.download_link);
                                        SetSpssLink(result.download_link_spss);

                                        SetGeneratedFiles(true); */

										setLoading(false);
										toast.current.show({ severity: 'success', summary: 'Success', detail: 'Data transformation completed successfully. Please download generated files.' });
									})
									.catch((error) => {
										SetGeneratedFiles(false);
										SetDataLink(null);
										SetSpssLink(null);

										setLoading(false);
										toast.current.show({ severity: 'error', summary: 'File Error', detail: 'Error in provided files. Please try again.' });
										// console.log(error);
									});

								setDataProgress(100);
								// setLoading(false);
								toast.current.show({ severity: 'success', summary: 'Success', detail: 'ODK Data file uploaded successfully.' });
							})
							.catch((error) => {
								setDataProgress(0);
								setLoading(false);
								toast.current.show({ severity: 'error', summary: 'File Error', detail: 'Problem uploading ODK Data file. Please try again.' });
								// console.log(error);
							});
					}
				);
				return itemContent;
			}
		);
	};

	const onAdvancedFormUpload = (event) => {
		setLoading(true);
		setFormProgress(50);

		const get_file_content = (file) => {
			return new Promise((acc, err) => {
				const reader = new FileReader();
				reader.onload = (ev) => { acc(ev.target.result); };
				reader.onerror = (er) => { er(er); };
				reader.readAsArrayBuffer(file);
			});
		};

		const contents = event.files.map(
			(item) => {
				const itemContent = get_file_content(item).then(
					(response) => {
						const responseData = response;

						const config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://api.node.scio.services/api/naupload',
							headers: {
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							},
							responseData,
						};

						axios.request(config)
							.then((response1) => {
								setForm(response1.data);

								setFormProgress(100);
								setLoading(false);
								toast.current.show({ severity: 'success', summary: 'Success', detail: 'XLS Form uploaded successfully.' });
							})
							.catch((error) => {
								setFormProgress(0);
								setLoading(false);
								toast.current.show({ severity: 'error', summary: 'File Error', detail: 'Problem uploading XLS Form. Please try again.' });

								// console.log(error);
							});
					}
				);
				return itemContent;
			}
		);
	};

	const onAdvancedDataUpload = (event) => {
		setLoading(true);
		setDataProgress(50);

		const get_file_content = (file) => {
			return new Promise((acc, err) => {
				const reader = new FileReader();
				reader.onload = (ev) => { acc(ev.target.result); };
				reader.onerror = (er) => { er(er); };
				reader.readAsArrayBuffer(file);
			});
		};

		const contents = event.files.map(
			(item) => {
				const itemContent = get_file_content(item).then(
					(response) => {
						const responseData = response;

						const config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://api.node.scio.services/api/naupload',
							headers: {
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							},
							responseData,
						};

						axios.request(config)
							.then((response1) => {
								setData(response1.data);
								setDataProgress(100);
								setLoading(false);
								toast.current.show({ severity: 'success', summary: 'Success', detail: 'ODK Data file uploaded successfully.' });
							})
							.catch((error) => {
								setDataProgress(0);
								setLoading(false);
								toast.current.show({ severity: 'error', summary: 'File Error', detail: 'Problem uploading ODK Data file. Please try again.' });
								// console.log(error);
							});
					}
				);
				return itemContent;
			}
		);
	};

	const onAdvancedCodebookUpload = (event) => {
		setLoading(true);
		setCodebookProgress(50);

		const get_file_content = (file) => {
			return new Promise((acc, err) => {
				const reader = new FileReader();
				reader.onload = (ev) => { acc(ev.target.result); };
				reader.onerror = (er) => { er(er); };
				reader.readAsArrayBuffer(file);
			});
		};

		const contents = event.files.map(
			(item) => {
				const itemContent = get_file_content(item).then(
					(response) => {
						const responseData = response;

						const config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://api.node.scio.services/api/naupload',
							headers: {
								'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							},
							responseData,
						};

						axios.request(config)
							.then((response1) => {
								setCodebook(response1.data);
								setCodebookProgress(100);
								setLoading(false);
								toast.current.show({ severity: 'success', summary: 'Success', detail: 'Codebook uploaded successfully.' });
							})
							.catch((error) => {
								setCodebookProgress(0);
								setLoading(false);
								toast.current.show({ severity: 'error', summary: 'File Error', detail: 'Problem uploading Codebook. Please try again.' });
								// console.log(error);
							});
					}
				);
				return itemContent;
			}
		);
	};

	const transform = (body, type) => {
		ToolsService.cleanSurveyData(user.sub, body, type)
			.then((res) => {
				const link = document.createElement('a');
				link.href = res;
				link.setAttribute('download', '');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	};

	const itemTemplateSimple = (file, props) => {
		return (
			<>
				<div>{file.name}</div>
				<div>{props.formatSize}</div>
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onSimpleTemplateRemove(file, props.onRemove)} />

			</>

		);
	};

	const onSimpleTemplateRemove = (file, callback) => {
		setTotalSimpleSize(0);
		callback();
	};

	const onSimpleTemplateSelect = (e) => {
		let totalSize = 0;
		const { files } = e;

		Object.keys(files).forEach((key) => {
			totalSize += files[key].size || 0;
		});

		setTotalSimpleSize(totalSize);
	};

	const onSimpleTemplateClear = () => {
		setTotalSimpleSize(0);
	};

	const headerSimpleTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		if (totalSimpleSize === 0) {
			setSimpleProgress(0);
		}

		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSimpleSize) : '0 B';

		return (
			<>
				<div style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
					<div style={{ width: '-webkit-fill-available' }}>
						{chooseButton}
						{uploadButton}
						{cancelButton}
					</div>
					<div className="flex align-items-center gap-3 ml-auto" style={{ width: '-webkit-fill-available' }}>
						<span>{formatedValue} / 50 MB</span>
					</div>

				</div>
				<ProgressBar value={simpleProgress} showValue={false} style={{ width: '100%', height: '12px', marginTop: '5px' }} />
			</>

		);
	};

	const itemTemplateXlsForm = (file, props) => {
		return (
			<>
				<div>{file.name}</div>
				<div>{props.formatSize}</div>
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onXlsFormTemplateRemove(file, props.onRemove)} />
			</>

		);
	};

	const onXlsFormTemplateRemove = (file, callback) => {
		setForm(false);
		SetGeneratedFiles(false);
		setTotalXlsFormSize(0);
		callback();
	};

	const onXlsFormTemplateSelect = (e) => {
		let totalSize = 0;
		const { files } = e;

		Object.keys(files).forEach((key) => {
			totalSize += files[key].size || 0;
		});

		setTotalXlsFormSize(totalSize);
	};

	const onXlsFormTemplateClear = () => {
		setTotalXlsFormSize(0);
	};

	const headerXlsFormTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		if (totalXlsFormSize === 0) {
			setFormProgress(0);
		}

		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalXlsFormSize) : '0 B';

		return (
			<>
				<div style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
					<div style={{ width: '-webkit-fill-available' }}>
						{chooseButton}
						{uploadButton}
						{cancelButton}
					</div>
					<div className="flex align-items-center gap-3 ml-auto" style={{ width: '-webkit-fill-available' }}>
						<span>{formatedValue} / 50 MB</span>
					</div>

				</div>
				<ProgressBar value={formProgress} showValue={false} style={{ width: '100%', height: '12px', marginTop: '5px' }} />
			</>

		);
	};

	const itemTemplateCodebook = (file, props) => {
		return (
			<>
				<div>{file.name}</div>
				<div>{props.formatSize}</div>
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onCodebookTemplateRemove(file, props.onRemove)} />
			</>

		);
	};

	const onCodebookTemplateRemove = (file, callback) => {
		setCodebook(false);
		SetGeneratedFiles(false);
		setTotalCodebookSize(0);
		callback();
	};

	const onCodebookTemplateSelect = (e) => {
		let totalSize = 0;
		const { files } = e;

		Object.keys(files).forEach((key) => {
			totalSize += files[key].size || 0;
		});

		setTotalCodebookSize(totalSize);
	};

	const onCodebookTemplateClear = () => {
		setTotalCodebookSize(0);
	};

	const headerCodebookTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		if (totalCodebookSize === 0) {
			setCodebookProgress(0);
		}

		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalCodebookSize) : '0 B';

		return (
			<>
				<div style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
					<div style={{ width: '-webkit-fill-available' }}>
						{chooseButton}
						{uploadButton}
						{cancelButton}
					</div>
					<div className="flex align-items-center gap-3 ml-auto" style={{ width: '-webkit-fill-available' }}>
						<span>{formatedValue} / 50 MB</span>
					</div>

				</div>
				<ProgressBar value={codebookProgress} showValue={false} style={{ width: '100%', height: '12px', marginTop: '5px' }} />
			</>

		);
	};

	const itemTemplateData = (file, props) => {
		return (
			<>
				<div>{file.name}</div>
				<div>{props.formatSize}</div>
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onDataTemplateRemove(file, props.onRemove)} />
			</>

		);
	};

	const onDataTemplateRemove = (file, callback) => {
		setData(false);
		SetGeneratedFiles(false);
		setTotalDataSize(0);
		callback();
	};

	const onDataTemplateSelect = (e) => {
		let totalSize = 0;
		const { files } = e;

		Object.keys(files).forEach((key) => {
			totalSize += files[key].size || 0;
		});

		setTotalDataSize(totalSize);
	};

	const onDataTemplateClear = () => {
		setTotalDataSize(0);
	};

	const headerDataTemplate = (options) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		if (totalDataSize === 0) {
			setDataProgress(0);
		}

		const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalDataSize) : '0 B';

		return (
			<>
				<div style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
					<div style={{ width: '-webkit-fill-available' }}>
						{chooseButton}
						{uploadButton}
						{cancelButton}
					</div>
					<div className="flex align-items-center gap-3 ml-auto" style={{ width: '-webkit-fill-available' }}>
						<span>{formatedValue} / 50 MB</span>
					</div>

				</div>
				<ProgressBar value={dataProgress} showValue={false} style={{ width: '100%', height: '12px', marginTop: '5px' }} />
			</>

		);
	};

	const renderFooterButtons = () => {
		if (!downloadLink) {
			if (checked) {
				return (
					<>

						<p style={{ marginTop: '25px' }}><u><strong>IMPORTANT NOTICE</strong></u>: Keep in mind that the transformation service may take some time!</p>
						<Button
							className="transform"
							label="Transform Data using Codebook"
							icon="pi pi-cog"
							style={{ marginTop: '15px', marginBottom: '20px' }}
							onClick={() => transform({
								s3DataKey: completedSurveyUploads[0],
								s3FormKey: completedQuestionnaireUploads[0],
								s3CodebookKey: completedCodebookUploads[0],
							}, 'full')}
							disabled={!(completedSurveyUploads.length && completedQuestionnaireUploads.length && completedCodebookUploads.length)}
						/>

					</>
				);
			} 
			return (
				<Button
					icon="fa-solid fa-arrow-progress"
					label="Transform file"
					loading={loading}
					disabled={!completedSurveyUploads.length}
					onClick={() => transform({
						s3DataKey: completedSurveyUploads[0],
					}, 'simple')}
				/>
			);
		} 
		return (
			<>

				<a href={downloadLink}>
					<Button type="submit" label="Download Data file (.xlsx)" icon="pi pi-download" style={{ marginTop: '40px', marginBottom: '20px', marginRight: '40px' }} />
				</a>

				<a href={downloadLink}>
					<Button label="Download SPSS file (.sps)" icon="pi pi-download" style={{ marginTop: '40px', marginBottom: '20px' }} />
				</a>

			</>
		);
	};

	return (
		<div className="data-cleaner">

			{loading
				? (
					<div className="progress-spinner">
						<ProgressSpinner />

					</div>
				)
				: null
			}

			<div className="search-bar-layout-content odk-page">
				<Fieldset className="fieldset-odk" legend="Survey Data Cleaner">
					<p>
						This tool removes DataScribe code and group paths from column headers and creates metadatabs tab to save this information.
					</p>
					<p style={{ fontSize: '20px' }}>
						To use this tool your dataset must:
					</p>
					<p>
						<ul>
							<li>
								Be in Excel format.
							</li>
							<li>
								“Include labels” (in the Advanced export options).
							</li>
							<li>
								Have group names (Untick “Remove prefixed group names” in the Advanced export options).
							</li>
						</ul>
					</p>
					<p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						<label htmlFor="binary">Use Codebook </label>
						<Checkbox onChange={(e) => setChecked(e.checked)} checked={checked} />
					</p>
					<Toast ref={toast} />
					<div hidden={!checked} style={{ display: !checked ? 'none' : 'flex', flexDirection: 'column', gap: '12px', visibility: !checked ? 'hidden' : '' }}>
						<p>
							If you want to use a specific numeric encoding (codebook) for the answer values of the multichoice questions, please provide:
						</p>
						<p>
							-	the dataset in the format mentioned above,
						</p>
						<p>
							-	the ODK questionnaire,
						</p>
						<p>
							-   a codebook file that defines the mappings between choice names and the code values you want to use.
						</p>
						<p>
							The tool accepts codebooks as 3-column CSV files with a specific structure:
						</p>
						<p>
							<ul>
								<li>
									the first column contains the list name of the list that defines the choice;
								</li>
								<li>
									the second column contains the name of the choice;
								</li>
								<li>
									the third column contains the numeric code corresponding to the choice.
								</li>
							</ul>
						</p>
						<p>
							Keep in mind that the first row of the codebook CSV file is considered as the header and is not used by the system. In this case, the system also produces an .sps file that can be loaded into SPSS to use the defined label/code mapping within SPSS.
						</p>
						<p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
							<label htmlFor="binary">Use STATA-conformant variable names </label>
							<Checkbox onChange={(e) => setStataCompatible(e.checked)} checked={stataCompatible} />
						</p>
						<p>
							The tool allows to get STATA conformant variable names (i.e. variable&apos;s name under 32 characters). When ticking this option, variable names follow a consistent varNNNNNN pattern, with mappings to the original names presented in a metadata sheet.
						</p>
						<div className="upload-container">
							<h4>Please upload your dataset in Excel format</h4>
							<UploadComponent
								completedUploads={completedQuestionnaireUploads}
								setCompletedUploads={setCompletedQuestionnaireUploads}
								devUrl={process.env.REACT_APP_BACKEND_URL}
								uppyType="dashboard"
								accessToken={location.state.token}
								restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['.xlsx'] }}
							/>
						</div>
						<Divider layout="vertical" />
						<div className="upload-container">
							<h4>Please upload your codebook</h4>
							<UploadComponent
								completedUploads={completedCodebookUploads}
								setCompletedUploads={setCompletedCodebookUploads}
								devUrl={process.env.REACT_APP_BACKEND_URL}
								uppyType="dashboard"
								accessToken={location.state.token}
								restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['.csv'] }}
							/>
						</div>

					</div>
					<div className="flex">
						<div className="uploader-container">
							<div className="upload-survey">
								<h4>Please upload your survey data</h4>
								<UploadComponent
									completedUploads={completedSurveyUploads}
									setCompletedUploads={setCompletedSurveyUploads}
									devUrl={process.env.REACT_APP_BACKEND_URL}
									uppyType="dashboard"
									accessToken={location.state.token}
									restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['.xlsx'] }}
								/>
							</div>
						</div>
					</div>
					{renderFooterButtons()}
				</Fieldset>

			</div>

		</div>

	);
};

export default DataCleaner;
