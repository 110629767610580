import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { httpUnintercepted } from '../../services/httpService';

const renameDatapool = async (userId, datapoolId, name) => {
	const result = await httpUnintercepted.patch(`user/${userId}/datapools/${datapoolId}/rename`, { name });
	return result.data;
};
export const useRenameDatapool = (userId, datapoolId, setLoading, setDialogVisibility, setName, queryClient) => useMutation({
	mutationFn: ({ name, datapool }) =>
		renameDatapool(userId, datapoolId, name)
			.then(() => {
				// eslint-disable-next-line no-param-reassign
				datapool.datapool_name = name;
			}),
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
	},
	onSettled: () => {
		setLoading(false);
		setDialogVisibility(false);
		setName('');
	},
});

const lockDatapool = async (userId, datapoolId) => {
	const result = await httpUnintercepted.patch(`user/${userId}/datapools/${datapoolId}/lock`);
	return result.data;
};

export const useLockDatapool = (queryClient, setLockLoading) => useMutation({
	mutationFn: (datapool) =>
		lockDatapool(datapool.identity_provider_id, datapool.mongo_id)
			.then((res) => {
				// eslint-disable-next-line no-param-reassign
				datapool.status = 'private';
			}),
	onSuccess: () => {
		queryClient.invalidateQueries('getPublicDatapools');
		queryClient.invalidateQueries('getUserDatapools');
		queryClient.invalidateQueries('getPinnedDatapools');
		queryClient.invalidateQueries('getDatapoolById');
		queryClient.invalidateQueries('getDatafileDatapools');
	},
	onSettled: () => {
		setLockLoading(false); 
	},
});

const getCurrentCodebookPresigned = async (userId, datapoolId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datapools/${datapoolId}/codebook/download`);
	return result.data;
};

export const useGetCurrentCodebookPresigned = (datapool, download) => useQuery({
	staleTime: Infinity,
	enabled: !!download,
	queryKey: ['getCurrentCodebookDownloadUrl', datapool],
	queryFn: () =>
		getCurrentCodebookPresigned(datapool.identity_provider_id, datapool.mongo_id)
			.then((res) => {
				return res;
			}),
});
