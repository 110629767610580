import React, { useState } from 'react';
import './styles.css';
import { useLocation } from 'react-router-dom';
import { TabPanel, TabView } from 'primereact/tabview';
import { AccessControl, EditData } from './components';
import DatapoolHeader from '../../components/DatapoolHeader';
import { useGetDatapoolDatafiles } from './queryHooks';

const MyDataPools = ({ tab }) => {
	const location = useLocation();
	const [activeIndex, setActiveIndex] = useState(tab.index);

	const datapoolDatafiles = useGetDatapoolDatafiles(location.state.userId, location.state.datapoolId);

	const calculateVersion = () => {
		let newVersion = 0;
		if (datapoolDatafiles.data instanceof Array) {
			datapoolDatafiles.data.forEach((item) => {
				if (item.version > newVersion) {
					newVersion = item.version;
				}
			});
		}
		return newVersion;
	};
	const version = calculateVersion();

	const headerStatePerScreen = () => {
		const { datapool } = location.state;
		let screen = '';
		if (!version) {
			screen = 'add-version';
		} else if (datapool.status === 'public') {
			screen = 'metadata-lock';
		} else if (datapool.description || datapool.citation || datapool.license) {
			screen = 'metadata-publish';
		} else {
			screen = 'publish';
		}
		switch (screen) {
		case 'add-version': return { codebook: false, api: false, metadata: false, publish: false, lock: false, editName: true };
		case 'metadata-lock': return { codebook: true, api: false, metadata: true, publish: false, lock: true, editName: true };
		case 'metadata-publish': return { codebook: true, api: false, metadata: true, publish: true, lock: false, editName: true };
		case 'publish': return { codebook: true, api: false, metadata: false, publish: true, lock: false, editName: true };
		default: return null;
		}
	};

	return (
		<div className="my-datapool">
			<DatapoolHeader datapool={location.state.datapool} headerState={headerStatePerScreen()} version={version} />
			<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
				<TabPanel header="Access Control">
					<AccessControl location={location} />
				</TabPanel>
				<TabPanel header="Edit Data">
					<EditData location={location} datapoolDatafiles={datapoolDatafiles.data} version={version} loading={datapoolDatafiles.isLoading} />
				</TabPanel>
			</TabView>
		</div>
	);
};

export default MyDataPools;
