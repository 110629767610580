import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { socket } from '../utils/socket';

const useSockets = () => {
	const { user } = useAuth0();
	const [isConnected, setIsConnected] = useState(false);
	const queryClient = useQueryClient();
	const queryClientRef = useRef(queryClient);
	// console.log(queryClient);

	useEffect(() => {
		function onConnect() {
			console.log('connected');
			setIsConnected(true);
		}

		function onDisconnect() {
			console.log('disconnected');
			setIsConnected(false);
		}

		function onInvalidateCache(event) {
			console.log(event);
			const eventArray = JSON.parse(event);

			eventArray.forEach((eventObject) => {
				const invalidateArray = [eventObject.message];
				if (eventObject.id) {
					invalidateArray.push(eventObject.id);
				}
				queryClientRef.current.invalidateQueries({ queryKey: invalidateArray, exact: true });
			});
		}

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('invalidate-cache', onInvalidateCache);

		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('invalidate-cache', onInvalidateCache);
		};
	}, []);

	useEffect(() => {
		console.log(user);
		if (user && isConnected) {
			console.log(user.sub);
			socket.emit('identify', user?.sub);
		}
	}, [user, isConnected]);
};

export default useSockets;
