import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useDeleteDatapool } from '../../queryHooks';

const EditData = ({ location, datapoolDatafiles, version, loading }) => {
	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const [deleteDatapoolLoading, setDeleteDatapoolLoading] = useState(false);

	const deleteDatapool = useDeleteDatapool(location.state.userId, navigate, queryClient, setDeleteDatapoolLoading);

	const handleDeleteDP = () => {
		setDeleteDatapoolLoading(true);
		deleteDatapool.mutate(location.state.datapoolId);
	};

	const actionsTemplate = (data) => {
		return (
			<div style={{ display: 'flex', gap: '8px' }}>
				{/* <Button icon="fa-solid fa-user-minus" rounded outlined tooltip="Remove datafile" onClick={() => handleRemoveDatafile(data)} /> */}
			</div>
		);
	};

	return (
		<div className="edit-data">
			<div className="users-table">
				<div className="users-table-table-container">
					<p className="title">Edit Datapool Data</p>
					<DataTable value={datapoolDatafiles} showGridlines stripedRows loading={loading}>
						<Column field="version" header="Version" />
						<Column field="filename" header="Datafile" />
						<Column field="records" header="Datapoints" />
						<Column body={actionsTemplate} header="Actions" />
					</DataTable>
				</div>
			</div>
			<div className="buttons-container">
				<Button label="Delete Datapool" onClick={handleDeleteDP} severity="danger" loading={deleteDatapoolLoading} />
				<Button label="Add Data File" onClick={() => navigate('./AddVersion', { state: { ...location.state, version } })} disabled={datapoolDatafiles && datapoolDatafiles.length > 0} />
			</div>
		</div>

	);
};

export default EditData;
