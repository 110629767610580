import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useGenerateCodebook, useGetDatapoolDatafilesInverse } from '../../../../../../queryHooks';

const SelectDataFile = ({ location, setActiveStep, setTemplateUrl, targetVersion, selectedFile, setSelectedFile }) => {
	const [loading, setLoading] = useState(false);
	const [generate, setGenerate] = useState(false);

	const datafiles = useGetDatapoolDatafilesInverse(location.state.userId, location.state.datapoolId, location.state);

	const s3Location = `codebooks/cb_template_${location.state.datapool.alias}_${1}.xlsx`;
	const generateCodebook = useGenerateCodebook(location.state.userId, location.state.datapoolId, selectedFile.key, targetVersion, s3Location, generate, setLoading, setTemplateUrl, setActiveStep, setGenerate);

	// const handleAddDatafiles = () => {
	// 	console.log(selectedDatafiles);
	// 	DatafileService.addDatafilesToDatapool(location.state.userId, location.state.datapoolId, selectedDatafiles)
	// 		.then((res) => {
	// 			console.log(res);
	// 		}).finally(() => {
	// 			setSelectedDatafiles([]);
	// 			populateTable();
	// 		});
	// };

	const handleGenerate = () => {
		setGenerate(true);
	};

	return (
		<>
			<div className="users-table-table-container">
				<p className="title">List of Datafiles not associated with current Datapool</p>
				<DataTable
					value={datafiles.data}
					showGridlines
					stripedRows
					selectionMode="single"
					selection={selectedFile}
					onSelectionChange={(e) => setSelectedFile(e.value)}
					metaKeySelection={false}
					paginator
					rows={5}
					rowsPerPageOptions={[5, 10, 25, 50]}
					loading={datafiles.isLoading}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column selectionMode="single" headerStyle={{ width: '3rem' }} />
					<Column field="filename" header="File Name" />
				</DataTable>
			</div>
			<Button className="generate-template" label="Generate Codebook Template" icon="fa-solid fa-arrow-right" iconPos="right" onClick={handleGenerate} disabled={!selectedFile} loading={generateCodebook.isLoading} />;
		</>

	);
};

export default SelectDataFile;
