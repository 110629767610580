import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { httpUnintercepted } from '../../services/httpService';

const getDatapoints = async (index, publicStatus, userId) => {
	if (publicStatus) {
		const result = await httpUnintercepted.get(`datapools/public/search/${index}/datapoints`);
		return result.data;
	}
	const result = await httpUnintercepted.get(`user/${userId}/datapools/search/${index}/datapoints`);
	return result.data;
};
export const useGetDatapoints = (alias, publicDatapool, userId) => useQuery({
	staleTime: Infinity,
	queryKey: ['getDatapoints', alias],
	queryFn: () =>
		getDatapoints(alias, publicDatapool, userId)
			.then((res) => {
				return res.datasetid;
			}),
});

const getDatapoolSearchResults = async (id, from, to, query, publicStatus, userId) => {
	if (publicStatus) {
		const result = await httpUnintercepted.post(`/datapools/public/search/${id}/documents/${from}/${to}`, { ...query });
		return result.data;
	}
	const result = await httpUnintercepted.post(`user/${userId}/datapools/search/${id}/documents/${from}/${to}`, { ...query });
	return result.data;
};

export const useGetSearchResults = (alias, lazyState, elasticQuery, publicDatapool, userId, setHistogramVariable, triggerSearchResults, setTriggerSearchResults) => useQuery({
	staleTime: Infinity,
	enabled: !!triggerSearchResults,
	queryKey: ['getDatapoolSearchResults', alias, lazyState, elasticQuery],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		return getDatapoolSearchResults(alias, lazyState.first, lazyState.first + lazyState.rows, query, publicDatapool, userId)
			.finally(() => {
				setTriggerSearchResults(false);
				setHistogramVariable('');
			});
	},
});

const getHistogramData = async (variable, query, index, publicStatus, userId) => {
	if (publicStatus) {
		const result = await httpUnintercepted.post(`datapools/public/search/${index}/histogramData/${variable}`, { ...query });
		return result.data;
	}
	const result = await httpUnintercepted.post(`user/${userId}/datapools/search/${index}/histogramData/${variable}`, { ...query });
	return result.data;
};

export const useGetHistogramData = (variable, alias, elasticQuery, publicDatapool, histogramData, setGetHistogramData) => useQuery({
	staleTime: Infinity,
	enabled: !!histogramData,
	queryKey: ['getHistogramData', variable, alias, elasticQuery],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		query.bool.filter = [
			{
				range: {
					longitude: {
						gte: -2147483648,
					},
				},
			},
		];
		return getHistogramData(variable, query, alias, publicDatapool)
			.then((res) => {
				setGetHistogramData(false);
				let newChartData = res.frequencies;
				newChartData = newChartData.map((item) => {
					return [item.value, item.frequency];
				});
				return newChartData;
			});
	},
});

const exportCsv = async (index, query, publicStatus, userId) => {
	if (publicStatus) {
		const result = await httpUnintercepted.post(`datapools/public/search/${index}/exportCsv`, { ...query });
		return result.data;
	}
	const result = await httpUnintercepted.post(`user/${userId}/datapools/search/${index}/exportCsv`, { ...query });
	return result.data;
};

export const useExportCsv = (alias, elasticQuery, publicDatapool, userId, csv) => useQuery({
	staleTime: Infinity,
	enabled: !!csv,
	queryKey: ['exportCsv', alias, elasticQuery],
	queryFn: () => {
		const json = JSON.parse(elasticQuery);
		const query = JSON.parse(json.query);
		return exportCsv(alias, query, publicDatapool, userId);
	},
});

const createApi = async (userId, datapoolId, apiName, query, mongoQuery) => {
	const body = {
		name: apiName,
		query,
		mongoQuery,
	};
	const result = await httpUnintercepted.post(`user/${userId}/datapools/${datapoolId}/apis/create`, body);
	return result.data;
};

export const useCreateApi = (userId, selectedDatapool, toast, setCreateApiDialog, queryClient, setCreateApiLoading) => useMutation({
	mutationFn: ({ apiName, query, mongoQuery }) =>
		createApi(userId, selectedDatapool?.datapool_id, apiName, query, mongoQuery),
	onSuccess: () => {
		toast.current.show({ severity: 'success', summary: 'Success!', detail: 'The Api was created successfully' });
		queryClient.invalidateQueries('getAllUserApis');
	},
	onError: () => {
		toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
	},
	onSettled: () => {
		setCreateApiLoading(false);
		setCreateApiDialog(false);
	},
});

const getLinkByUUID = async (userId, uuid, isAuthenticated) => {
	if (isAuthenticated) {
		const result = await httpUnintercepted.get(`user/${userId}/datapools/search/shareableLink/${uuid}/get`);
		return result.data;
	}
	const result = await httpUnintercepted.get(`datapools/public/search/shareableLink/${uuid}/get`);
	return result.data;
};
export const useGetLinkByUUID = (userId, uuid, toast, navigate, isAuthenticated) => useQuery({
	staleTime: Infinity,
	enabled: !!uuid,
	queryKey: ['getShareableLink', uuid],
	queryFn: () =>
		getLinkByUUID(userId, uuid, isAuthenticated)
			.catch((error) => {
				if (error.response.status === 403) {
					toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data });
					setTimeout(() => {
						navigate('/Home');
					}, 4000);
				}
				if (error.response.status === 401) {
					toast.current.show({ severity: 'error', summary: 'Error', detail: 'Unauthorized' });
					setTimeout(() => {
						navigate('/Home');
					}, 2000);
				}
			}),
});

const generateShareableLink = async (userId, datapoolId, body) => {
	// if (publicStatus) {
	// 	const result = await http.post(`datapools/public/search/${index}/exportCsv`, { ...query });
	// 	return result.data;
	// }
	const result = await httpUnintercepted.post(`user/${userId}/datapools/search/${datapoolId}/shareableLink/create`, { ...body });
	return result.data;
};

export const useGenerateShareableLink = (userId, selectedDatapool, toast, setCreateShareableLinkLoading) => useMutation({
	mutationFn: (body) =>
		generateShareableLink(userId, selectedDatapool?.datapool_id, body),
	onSuccess: (r) => {
		navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/AdvancedSearch?linkId=${JSON.stringify(r).replaceAll('"', '')}`);
		toast.current.show({ severity: 'success', summary: 'Success!', detail: 'The Link was created successfully and copied to your clipboard' });
	},
	onError: () => {
		toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
	},
	onSettled: () => {
		setCreateShareableLinkLoading(false);
	},
});
