import { useMutation, useQuery } from '@tanstack/react-query';
import { httpUnintercepted } from './services/httpService';

const updateAuth0UserMetadata = async (userId, metadata) => {
	const result = await httpUnintercepted.post(`user/${userId}/updateAuth0Metadata`, metadata);
	return result.data;
};
// eslint-disable-next-line import/prefer-default-export
export const useUpdateAuth0UserMetadata = (user, getAccessTokenSilently) => useMutation({
	mutationFn: (metadata) => updateAuth0UserMetadata(user.sub, metadata),
	onSuccess: async () => {
		await getAccessTokenSilently({
			cacheMode: 'off',
		});
	},
	onError: (e) => {
	},
	onSettled: () => {

	},
});
