import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useGenerateVersion } from '../../../../../../queryHooks';

const GenerateDP = ({ location, codebook, validationStatus, selectedFile, templateUrl }) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const queryClient = useQueryClient();

	const generateVersion = useGenerateVersion(location.state.userId, location.state.datapoolId, location.state.version, location.state.datapool, setLoading, navigate, queryClient);
	
	const handleGenerateVersion = () => {
		setLoading(true);
		generateVersion.mutate({ selectedFile, codebook, key: templateUrl.key });
	};

	const renderContent = () => {
		if (validationStatus === 'valid') {
			return (
				<>
					<p>Codebook validated!</p>
					<Button className="bottom-button" label="Generate Datapool Version" onClick={handleGenerateVersion} loading={loading} />
				</>
			);
		} if (validationStatus === 'invalid') {
			return (
				<>
					<p>Validation issues.</p>
					<Button label="Correct Codebook" icon="fa-solid fa-arrow-left" onClick={handleGenerateVersion} loading={loading} />
				</>
			);
		}
		return null;
	};

	return (
		renderContent()
	);
};

export default GenerateDP;
