import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './styles.css';
import { GeneratedCode } from './components';

const ApiCodeGenerator = (props) => {
	const { configuration } = props;
	const [codegen, setCodegen] = useState(null);
	const [languageTabIndex, setLanguageTabIndex] = useState(0);
	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState('C#');
	const [variantTabIndex, setVariantTabIndex] = useState(0);

	useEffect(() => {
		// Polyfill for Buffer
		// eslint-disable-next-line global-require
		global.Buffer = global.Buffer || require('buffer').Buffer;
		global.process = { env: {} };

		// Import postman-code-generators after the polyfill
		import('postman-code-generators/lib')
			.then((cg) => {
				setCodegen(cg);
				let lang = cg.getLanguageList();
				const R = lang.find((item) => item.label === 'R');
				const python = lang.find((item) => item.label === 'Python');
				lang = lang.filter((item) => item.label !== 'R' && item.label !== 'Python');
				lang = [R, python, ...lang];
				lang = lang.filter((item) => item.label !== 'JavaScript');
				setLanguages(lang);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	const renderCodeSnippet = () => {
		return configuration.map((item) => {
			return (
				<div className="generated-code">
					<GeneratedCode
						requestBody={item.requestBody}
						requestConfiguration={item.requestConfiguration}
						additionalTextOnRequest={item.additionalTextOnRequest}
						languages={languages}
						codegen={codegen}
						selectedLanguage={selectedLanguage}
						variantTabIndex={variantTabIndex}
					/>
				</div>
			);
		});
	};

	const renderSubTabs = (variants) => {
		const renderVariants = () => {
			return variants.map((item) => {
				return (
					<TabPanel header={item.key}>
						<div className="code-snippet">
							{renderCodeSnippet()}
						</div>
					</TabPanel>
				);
			});
		};

		if (variants.length > 1) {
			return (
				<div className="code-generator">
					<TabView
						activeIndex={variantTabIndex}
						onTabChange={(e) => {
							setVariantTabIndex(e.index);
						}}
					>
						{renderVariants()}
					</TabView>
				</div>
			);
		}
		return (
			<div className="code-snippet">
				{renderCodeSnippet()}
			</div>
		);
	};

	const renderTabs = () => {
		if (codegen && languages) {
			return languages.map((item) => {
				return (
					<TabPanel header={item.label}>
						{renderSubTabs(item.variants)}
					</TabPanel>
				);
			});
		}
		return null;
	};

	return (
		<div className="code-generator">
			{/* <h4>Datapool ID: {datapool}</h4> */}
			<TabView
				scrollable
				activeIndex={languageTabIndex}
				onTabChange={(e) => {
					setSelectedLanguage(e.originalEvent.target.innerText);
					setVariantTabIndex(0);
					setLanguageTabIndex(e.index);
				}}
			>
				{renderTabs()}
			</TabView>
		</div>
	);
};

export default ApiCodeGenerator;
