import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import { http } from '../services/httpService';

const handleInvitation = (userId, invitationId, token) => {
	http.post(`user/${userId}/invite/${invitationId}/resolve`, {}, { headers: { Authorization: `Bearer ${token}` } })
		.then((response) => {
			console.log(response);
		});
};

const useAcceptInvitations = (token, init) => {
	const { isAuthenticated, isLoading, user, loginWithRedirect } = useAuth0();
	const [searchParams, setSearchParams] = useSearchParams();

	if (!isLoading) {
		let invitationId = searchParams.get('invitation');
		if (invitationId) {
			localStorage.setItem('invitation', invitationId);
			setSearchParams([]);
			if (!isAuthenticated) {
				loginWithRedirect();
			}
		} else if (init && isAuthenticated) {
			invitationId = localStorage.getItem('invitation');
			if (invitationId) {
				handleInvitation(user.sub, invitationId, token);
				localStorage.removeItem('invitation');
			}
		}
	}
};

export default useAcceptInvitations;
