import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';

const GeneratedCode = (props) => {
	const { requestBody, requestConfiguration, languages, codegen, selectedLanguage, variantTabIndex, additionalTextOnRequest } = props;

	const [snippetCode, setSnippetCode] = useState(null);
	const generateCodeSnippet = (languageLabel, variant) => {
		// eslint-disable-next-line global-require,import/no-extraneous-dependencies
		const sdk = require('postman-collection'); // require postman-collection in your project

		const body = new sdk.RequestBody(requestBody);

		const request = new sdk.Request({
			...requestConfiguration,
			body,
		});
		const languageObject = languages.find((item) => item.label === languageLabel);
		const language = languageObject.key;
		const selectedVariant = languageObject.variants[variant].key;

		const options = {
			indentCount: 3,
			indentType: 'Space',
			trimRequestBody: true,
			followRedirect: true,
		};

		codegen.convert(language, selectedVariant, request, options, (error, snippet) => {
			if (error) {
				console.log(error);
			} else {
				setSnippetCode(snippet);
			}
		});
	};

	useEffect(
		() => {
			if (languages.length) {
				generateCodeSnippet(selectedLanguage, variantTabIndex);
			}
		}, [selectedLanguage, variantTabIndex, languages, requestBody]
	);

	const renderCodeSnippet = () => {
		if (snippetCode) {
			const temp = snippetCode.replaceAll('\\', '');

			if (selectedLanguage === 'cURL') {
				return (
					<>
						<Button text rounded icon="fa-solid fa-copy" onClick={() => navigator.clipboard.writeText(temp.replaceAll('\n', ''))} />
						<p className="generated-text">{temp}</p>
					</>
				);
			}
			let snippetParts = temp.split('\n');
			if (additionalTextOnRequest) {
				snippetParts = [...additionalTextOnRequest, ...snippetParts];
			}
			snippetParts = snippetParts.map((item) => {
				return <p className="generated-text">{item}</p>;
			});

			snippetParts = [<Button text rounded icon="fa-solid fa-copy" onClick={() => navigator.clipboard.writeText(selectedLanguage === 'cURL' ? temp.replaceAll('\n', '') : temp)} />, ...snippetParts];
			return snippetParts;
		}
		return null;
	};

	return renderCodeSnippet();
};

export default GeneratedCode;
