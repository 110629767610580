import React from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { httpUnintercepted } from '../../services/httpService';

const getUserDatafiles = async (userId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datafiles/all/get`);
	return result.data;
};
export const useGetDatafiles = (user) => useQuery({
	staleTime: Infinity,
	queryKey: ['getDatapoolDatafiles'],
	queryFn: () => getUserDatafiles(user.sub),
});

const getRelatedDatapools = async (userId, datafileId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datafiles/${datafileId}/datapools/get`);
	return result.data;
};

export const useGetRelatedDatapools = (user, selectedDatafile) => useQuery({
	staleTime: Infinity,
	enabled: !!selectedDatafile,
	queryKey: ['getDatafileDatapools', selectedDatafile],
	queryFn: () =>
		getRelatedDatapools(user.sub, selectedDatafile)
			.then((res) => {
				return res;
			}),
});

const deleteDatafile = async (userId, dfId) => {
	const result = await httpUnintercepted.delete(`user/${userId}/datafiles/${dfId}/delete`);
	return result.data;
};

export const useDeleteDatafile = (user, queryClient, setDeleteDatafileLoading) => useMutation({
	mutationFn: (datafile) => {
		return deleteDatafile(user.sub, datafile.datafile_id);
	},
	onSuccess: () => {
		queryClient.invalidateQueries('getDatapoolDatafiles');
		queryClient.invalidateQueries('getPresignedUrl');
	},
	onSettled: () => {
		setDeleteDatafileLoading(false);
	},
});

const getPresignedUrl = async (userId, dfId) => {
	const result = await httpUnintercepted.get(`user/${userId}/datafiles/${dfId}/presigned/get`);
	return result.data;
};

export const useGetPresignedUrl = (user, downloadDatafile) => useQuery({
	staleTime: Infinity,
	enabled: !!downloadDatafile,
	queryKey: ['getPresignedUrl', downloadDatafile],
	queryFn: () =>
		getPresignedUrl(user.sub, downloadDatafile.datafile_id)
			.then((res) => {
				return res.url;
			}),
});

const upload = async (userId, fileKey) => {
	const result = await httpUnintercepted.post(`user/${userId}/datafiles/confirmUpload`, { fileKey });
	return result.data;
};

export const useUpload = (user, setCompletedUploads, queryClient) => useMutation({
	mutationFn: (completedUploads) => {
		return upload(user.sub, completedUploads);
	},
	onSuccess: () => {
		setCompletedUploads([]);
		queryClient.invalidateQueries('getDatapoolDatafiles');
	},
});
